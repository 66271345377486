import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Github = makeShortcode("Github");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`To build a price oracle on Kwikswap, you must first understand the
requirements for your use case. Once you understand the kind of price
average you require, it is a matter of storing the cumulative price
variable from the pair as often as necessary, and computing
the average price using two or more observations of the
cumulative price variables.`}</p>
    <h1 {...{
      "id": "understanding-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#understanding-requirements",
        "aria-label": "understanding requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Understanding requirements`}</h1>
    <p>{`To understand your requirements, you should first research the answer to the
following questions:`}</p>
    <ul>
      <li parentName="ul">{`Is data freshness important?
I.e.: must the price average include the current price?`}</li>
      <li parentName="ul">{`Are recent prices more important than historical prices?
I.e.: is the current price given more weight than historical prices?`}</li>
    </ul>
    <p>{`Note your answers for the following discussion.`}</p>
    <h1 {...{
      "id": "oracle-strategies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#oracle-strategies",
        "aria-label": "oracle strategies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Oracle Strategies`}</h1>
    <h2 {...{
      "id": "fixed-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fixed-windows",
        "aria-label": "fixed windows permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fixed windows`}</h2>
    <p>{`In the case where data freshness is not important and recent prices
are weighted equally with historical prices, it is enough to
store the cumulative price once per period (e.g. once per 24 hours.)`}</p>
    <p>{`Computing the average price over these data points gives you ‘fixed windows’,
which can be updated after the lapse of each period. We wrote
an example oracle of this kind
`}<a parentName="p" {...{
        "href": "https://github.com/Kwikswap/Kwikswap-V1-Periphery/blob/master/contracts/examples/ExampleOracleSimple.sol"
      }}>{`here`}</a>{`.`}</p>
    <Github href="https://github.com/Kwikswap/Kwikswap-V1-Periphery/blob/master/contracts/examples/ExampleOracleSimple.sol" mdxType="Github">ExampleOracleSimple.sol</Github>
    <p>{`This example does not limit the maximum size of the fixed window, i.e.
it only requires that the window size is greater than 1 period (e.g. 24 hours).`}</p>
    <h2 {...{
      "id": "moving-averages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#moving-averages",
        "aria-label": "moving averages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Moving averages`}</h2>
    <p>{`In the case where data freshness is important, you can use a sliding
window in which the cumulative price variable is measured more often
than once per period.`}</p>
    <p>{`There are at least
`}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/m/movingaverage.asp#types-of-moving-averages"
      }}>{`two kinds of moving averages`}</a>{`
that you can compute using the Kwikswap cumulative price variable.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/s/sma.asp"
      }}>{`Simple moving averages`}</a>{`
give equal weight to each price measurement. We have built
an example of a sliding window oracle
`}<a parentName="p" {...{
        "href": "https://github.com/Kwikswap/Kwikswap-V1-Periphery/blob/master/contracts/examples/ExampleSlidingWindowOracle.sol"
      }}>{`here`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/e/ema.asp"
      }}>{`Exponential moving averages`}</a>{`
give more weight to the most recent price measurements. We do not yet have an example written for this type of oracle.`}</p>
    <p>{`You may wish to use exponential moving averages where recent prices
are more important than historical prices, e.g. in case of liquidations. However, note that
putting more weight on recent prices makes the oracle cheaper to manipulate
than weighting all price measurements equally.`}</p>
    <h2 {...{
      "id": "computing-average-prices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#computing-average-prices",
        "aria-label": "computing average prices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Computing average prices`}</h2>
    <p>{`To compute the average price given two cumulative price observations, take the difference between
the cumulative price at the beginning and end of the period, and
divide by the elapsed time between them in seconds. This will produce a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Fixed-point_arithmetic#Notation"
      }}>{`fixed point unsigned Q112x112`}</a>{`
number that represents the price of one asset relative to the other. This number is represented as a `}<inlineCode parentName="p">{`uint224`}</inlineCode>{` where
the upper 112 bits represent the integer amount, and the lower 112 bits represent the fractional amount.`}</p>
    <p>{`Pairs contain both `}<inlineCode parentName="p">{`price0CumulativeLast`}</inlineCode>{` and `}<inlineCode parentName="p">{`price1CumulativeLast`}</inlineCode>{`, which are ratios of reserves
of `}<inlineCode parentName="p">{`token1`}</inlineCode>{`/`}<inlineCode parentName="p">{`token0`}</inlineCode>{` and `}<inlineCode parentName="p">{`token0`}</inlineCode>{`/`}<inlineCode parentName="p">{`token1`}</inlineCode>{` respectively. I.e. the price of `}<inlineCode parentName="p">{`token0`}</inlineCode>{` is expressed in terms of
`}<inlineCode parentName="p">{`token1`}</inlineCode>{`/`}<inlineCode parentName="p">{`token0`}</inlineCode>{`, while the price of `}<inlineCode parentName="p">{`token1`}</inlineCode>{` is expressed in terms of `}<inlineCode parentName="p">{`token0`}</inlineCode>{`/`}<inlineCode parentName="p">{`token1`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "getting-the-latest-cumulative-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-the-latest-cumulative-price",
        "aria-label": "getting the latest cumulative price permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting the latest cumulative price`}</h1>
    <p>{`If you wish to compute the average price between a historical price cumulative observation and the current cumulative
price, you should use the cumulative price values from the current block. If the cumulative price has not been updated
in the current block, e.g. because there has not been any liquidity event (`}<inlineCode parentName="p">{`mint`}</inlineCode>{`/`}<inlineCode parentName="p">{`burn`}</inlineCode>{`/`}<inlineCode parentName="p">{`swap`}</inlineCode>{`) on the pair in the current
block, you can compute the cumulative price counterfactually.`}</p>
    <p>{`We provide a library for use in oracle contracts that has the method
`}<a parentName="p" {...{
        "href": "https://github.com/Kwikswap/Kwikswap-V1-Periphery/blob/master/contracts/libraries/KwikswapV1OracleLibrary.sol#L16"
      }}><inlineCode parentName="a">{`KwikswapV1OracleLibrary#currentCumulativePrices`}</inlineCode></a>{`
for getting the cumulative price as of the current block.
The current cumulative price returned by this method is computed `}<em parentName="p">{`counterfactually`}</em>{`, meaning it requires no call to
the relative gas-expensive `}<inlineCode parentName="p">{`#sync`}</inlineCode>{` method on the pair.
It is correct regardless of whether a swap has already executed in the current block.`}</p>
    <h1 {...{
      "id": "notes-on-overflow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#notes-on-overflow",
        "aria-label": "notes on overflow permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Notes on overflow`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`KwikswapV1Pair`}</inlineCode>{` cumulative price variables are designed to eventually overflow,
i.e. `}<inlineCode parentName="p">{`price0CumulativeLast`}</inlineCode>{` and `}<inlineCode parentName="p">{`price1CumulativeLast`}</inlineCode>{` and `}<inlineCode parentName="p">{`blockTimestampLast`}</inlineCode>{` will overflow through 0.`}</p>
    <p>{`This should not pose an issue to your oracle design, as the price average computation is concerned with differences
(i.e. subtraction) between two separate observations of a cumulative price variable.
Subtracting between two cumulative price values will result in a number that fits within the range of `}<inlineCode parentName="p">{`uint256`}</inlineCode>{` as long
as the observations are made for periods of max `}<inlineCode parentName="p">{`2^32`}</inlineCode>{` seconds, or ~136 years.`}</p>
    <p><inlineCode parentName="p">{`blockTimestampLast`}</inlineCode>{` is stored only in a `}<inlineCode parentName="p">{`uint32`}</inlineCode>{`. For the same reason as described above, the pair can save a
storage slot, and many SSTORES over the life of the pair, by storing only `}<inlineCode parentName="p">{`block.timestamp % uint32(-1)`}</inlineCode>{`.
This is feasible because the pair is only concerned with the time that elapses between each liquidity event when updating
the cumulative prices, which is always expected to be less than `}<inlineCode parentName="p">{`2^32`}</inlineCode>{` seconds.`}</p>
    <p>{`When computing time elapsed within your own oracle, you can simply store the `}<inlineCode parentName="p">{`block.timestamp`}</inlineCode>{` of your observations
as `}<inlineCode parentName="p">{`uint256`}</inlineCode>{`, and avoid dealing with overflow math for computing the time elapsed between observations. This is how the
`}<a parentName="p" {...{
        "href": "https://github.com/Kwikswap/Kwikswap-V1-Periphery/blob/master/contracts/examples/ExampleSlidingWindowOracle.sol"
      }}>{`ExampleSlidingWindowOracle`}</a>{`
handles observation timestamps.`}</p>
    <Github href="https://github.com/Kwikswap/Kwikswap-V1-Periphery/blob/master/contracts/examples/ExampleSlidingWindowOracle.sol" mdxType="Github">ExampleSlidingWindowOracle</Github>
    <h2 {...{
      "id": "integrating-the-oracle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integrating-the-oracle",
        "aria-label": "integrating the oracle permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integrating the oracle`}</h2>
    <p>{`To integrate an oracle into your contracts, you must ensure the oracle’s observations of the cumulative price variable
are kept up to date.
As long as your oracle is up to date, you can depend on it to produce average prices.
The process of keeping your oracle up to date is called ‘maintenance’.`}</p>
    <h2 {...{
      "id": "oracle-maintenance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#oracle-maintenance",
        "aria-label": "oracle maintenance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Oracle maintenance`}</h2>
    <p>{`In order to measure average prices over a period, the oracle must have a way
of referencing the cumulative price at the start and end of a period.
The recommended way of doing this is by storing these prices in the oracle contract,
and calling the oracle frequently enough to store the latest cumulative price.`}</p>
    <p>{`Reliable oracle maintenance is a difficult task,
and can become a point of failure in times of congestion.
Instead, consider building this functionality directly into the
critical calls of your own smart contracts, or incentivize oracle
maintenance calls by other parties.`}</p>
    <h2 {...{
      "id": "no-maintenance-option",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#no-maintenance-option",
        "aria-label": "no maintenance option permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`No-maintenance option`}</h2>
    <p>{`It is possible to avoid regularly storing this cumulative price at the
start of the period by utilizing storage proofs. However, this approach has limitations,
especially in regard to gas cost and maximum length of the time period over which the average price can be measured.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      